/*jslint node: true */
"use strict";

var _ = require('lodash')
var colors = require('../../data').colors
var camoColors = require('../../data').whitelists.colors.camo

exports.mapColor = function(color, hexPropName) {
    if(!color) {
        return console.error('mapColor() called without a color value')
    }
    if(color == 'none') {
        return null
    }

    if(_.isString(color) && color[0] == '#') {
        return color
    }

    if(! colors[color]) {
        return null
    }
    return colors[color][hexPropName || 'hex']
}

exports.getFill = function(fillName, layers) {
    if(_.contains(camoColors, fillName)){
        return {
            type: 'render',
            placement: layers,
            selfmask: false,
            sides: 'both',
            patternFill: {
                pattern: fillName,
                colors: [],
            },
        }
    }
    return exports.mapColor(fillName)
}

exports.getAltColor = function(color, propName) {
    var altColorId = colors[color][propName || 'alt']
    return exports.mapColor(altColorId)
}
