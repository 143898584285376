module.exports={
    "jet": {
        "name": "Jet",
        "code": "jet"
    },
    "sweep": {
        "name": "Sweep",
        "code": "sweep"
    },
    "standard": {
        "name": "Standard",
        "code": "standard"
    },
    "stanford": {
        "name": "Stanford",
        "code": "stanford"
    }
}