
exports.colors			= require('./sheets/colors.json')
exports.decoLocations	= require('./sheets/decoLocations.json')
exports.decoTypes		= require('./sheets/decoTypes.json')
exports.decos			= require('./sheets/decos.json')
exports.designs			= require('./sheets/designs.json')
exports.fonts			= require('./sheets/fonts.json')
exports.garments		= require('./sheets/garments.json')
exports.layouts			= require('./sheets/layouts.json')
exports.rosterSizes		= require('./sheets/rosterSizes.json')
exports.tailings		= require('./sheets/tailings.json')

exports.decoTypeLimitations = {
	tackleTwill: {
        hideNavigation: {
            design: true,
            frontLogo: true,
            sleeveLogo: true,
        },
    },
}

exports.toggles = {
	on: {
        'code': 'on',
        'value': 'On',
    },
	off: {
        'code': 'off',
        'value': 'Off',
    },
}

exports.whitelists		= require('./sheets/whitelists.json')


exports.whitelists.toggles = {
    'all': ['on', 'off',],
}
