/*jslint node: true */
"use strict";

var _ = require('lodash')
var mapColor = require('../../viz/lib/utils').mapColor
var camoColors = require('../../data').whitelists.colors.camo

function mapPlacement(id) {
    return ['*.' + id]
}


function getTeamname(design) {
    var teamname = design.teamName;

    if (teamname.location === 'none' || teamname.text === '') {
        return;
    }
    var lettering = {
        height: 128,
        fixheight: false,
        align: 'm',
        text: teamname.text,
        font: teamname.font,
        layout: teamname.layout,
        color: {
            fill: mapColor(teamname.color) || 'black',
            outline1: mapColor(teamname.outlineColor) || 'transparent',
        },
    }

    if(_.contains(camoColors, teamname.color)) {
        lettering.fill = {
            pattern: teamname.color,
            colors: [],
        }
    }

    if (teamname.tail && teamname.tail.enabled === 'on') {
       var tail = teamname.tail
       lettering.tailing = {
           tailing: (_.isEqual(tail.type, 'sweep') ? '' : teamname.font + '-') + tail.type,
           text: tail.text,
           font: tail.font,
           color: {
               fill: mapColor(tail.color) || 'black'
           },
       }
    }

    return {
        type: 'render',
        target: 'teamname',
        placement: mapPlacement(teamname.location),
        placeholder: true,
        lettering: lettering,
    };
}

function getPlayerName(design) {
    var playerName = design.playerName;

    if (playerName.location === 'none' || playerName.text === '') {
        return;
    }
    var lettering = {
        height: 128,
        fixheight: false,
        align: 'm',
        text: playerName.text,
        font: playerName.font,
        layout: playerName.layout,
        color: {
            fill: mapColor(playerName.color) || 'black',
            outline1: mapColor(playerName.outlineColor) || 'transparent',
        },
    }

    if(_.contains(camoColors, playerName.color)) {
        lettering.fill = {
            pattern: playerName.color,
            colors: [],
        }
    }

    return {
        type: 'render',
        target: 'teamname',
        placement: mapPlacement(playerName.location),
        placeholder: true,
        lettering: lettering,
    };
}

function getLogo(logoProp, design) {
    var logo = design[logoProp]

    if (!logo || logo.location == 'none')
        return null;

    if (!logo.previewFile)
        return null;

    var image = {
        align: 'm',
        buffer: logo.buffer,
        uri: logo.previewFile,
    }

    return {
        type: 'render',
        target: 'logo',
        placement: mapPlacement(logo.location),
        placeholder: true,
        image: image,
    }
}

function getNumber(locationProp, design) {
    var number = design.number
    var placement = number[locationProp]

    if (!number || placement == 'none')
        return null;


    var lettering = {
        height: 128,
        fixheight: false,
        align: 'm',
        text: number.number,
        font: number.font,
        color: {
        fill: mapColor(number.color) || 'black',
            outline1: mapColor(number.outlineColor) || 'transparent',
        },
    }

    if(_.contains(camoColors, number.color)) {
        lettering.fill = {
            pattern: number.color,
            colors: [],
        }
    }

    return {
        type: 'render',
        target: 'number',
        placement: mapPlacement(placement),
        placeholder: true,
        lettering: lettering,
    }
}

module.exports = {
    teamname: getTeamname,
    frontLogo: _.bind(getLogo, null, 'frontLogo'),
    sleeveLogo: _.bind(getLogo, null, 'sleeveLogo'),
    frontNumber: _.bind(getNumber, null, 'frontLocation' ),
    backNumber: _.bind(getNumber, null, 'backLocation' ),
    playerName: getPlayerName,
}
