/*jslint node: true */
"use strict";

var  _ = require('lodash')

var defaultView = {
    fov: 47,
    viewport: {
        width: 1000,
        height: 1000,
    },
}

var viewsSettings = {
    front: {
        lookAt: {
            x: -0.03,
            y: -0.04,
            z: 0,
        },
        pan: -10.4,
        tilt: -12.1,
        distance: 1.046,
    },
    back: {
        lookAt: {
            x: 0.02,
            y: -0.05,
            z: 0,
        },
        pan: 170.193,
        tilt: -11.391,
        distance: 1.114,
    },
    left: {
        lookAt: {
            x: 0,
            y: -0.05,
            z: 0,
        },
        pan: 90,
        tilt: -8.484,
        distance: 1.058,
    },
    right: {
        lookAt: {
            x: 0,
            y: -0.05,
            z: 0,
        },
        pan: -90,
        tilt: -8.484,
        distance: 1.058,
    },
    icon: {
        lookAt: {
            x: 0,
            y: -0.03,
            z: 0.01,
        },
        pan: 56.666,
        tilt: -14.688,
        distance: 1.646,
        fov: 30,
    },
}

function wrapView(view, name) {
    return {
        name: name,
        setView: function(scene, frameSize) {
            scene.camera.fov = view.fov;
            scene.camera.near = view.distance*0.01
            scene.camera.far = view.distance*2
            scene.camera.aspect = frameSize.width / frameSize.height; // TODO define dimensions (and thus aspect) in view settings

            var offset = new THREE.Vector3();
            var phi = (90 + view.tilt) * Math.PI / 180;
            var theta = view.pan * Math.PI / 180;
            offset.x = view.distance * Math.sin( phi ) * Math.sin( theta );
            offset.y = view.distance * Math.cos( phi );
            offset.z = view.distance * Math.sin( phi ) * Math.cos( theta );

            var target = new THREE.Vector3();
            if(view.lookAt) {
                target.copy(view.lookAt);
                offset.add(target);
            }
            scene.camera.position.copy(offset);
            scene.camera.lookAt(target);
            scene.camera.updateProjectionMatrix();
        }
    }
}

var views = _.chain(viewsSettings)
    .mapValues(function(view){
        return _.merge({}, defaultView, view)
    })
    .mapValues(wrapView)
    .value()

exports.getView = function getView(side) {
    return views[side];
}
