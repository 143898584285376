module.exports={
    "sublimation": {
        "name": "Sublimation",
        "code": "sublimation",
        "implemented": true,
        "silhouetteList": [
            "2ButtonHenley-raglan",
            "2ButtonHenley-setIn",
            "crewNeck-raglan",
            "crewNeck-setIn",
            "fullButton-raglan",
            "fullButton-setIn",
            "vNeck-raglan",
            "vNeck-setIn"
        ]
    },
    "tackleTwill": {
        "name": "Tackle Twill",
        "code": "tackleTwill",
        "implemented": true,
        "silhouetteList": [
            "fullButton-setIn"
        ]
    },
    "fullCustom": {
        "name": "Full Custom",
        "code": "fullCustom",
        "implemented": false,
        "silhouetteList": [
            "2ButtonHenley-raglan",
            "2ButtonHenley-setIn",
            "crewNeck-raglan",
            "crewNeck-setIn",
            "fullButton-raglan",
            "fullButton-setIn",
            "vNeck-raglan",
            "vNeck-setIn"
        ]
    }
}