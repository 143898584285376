/*jslint node: true */
"use strict";

module.exports = getCanvasLayersExported
// module.exports = getCanvasLayers

var _ = require('lodash')

var garments = require('../../data').garments
var layoutConfig = require('./layoutConfig')

var dynamicLayerConfig = require('./dynamicLayerConfig')

function processData(data) {
    var jersey = garments[data.jersey]
    data.model = jersey.key

    if (_.isEqual(data.decoType, 'tackleTwill')) {
        _.each(data.colors, function(color, key) {
            if (_.isEqual(color, 'none')) {
                data.colors[key] = 'white'
            }
        })
    }
}

function isActiveView(activeView, layerDef) {
    //console.log('isActiveView', activeView, layerDef )
    return _.contains(layerDef.views, 'all') || _.contains(layerDef.views, activeView);
}

function createLayer(designDataOutput, layerDef) {
    if(layerDef.placeholder) {
        return dynamicLayerConfig[layerDef.name] && dynamicLayerConfig[layerDef.name](designDataOutput)
    }

    var fill = _.has(layerDef, 'fill') ? layerDef.fill : true
    if(_.isFunction(fill)) fill = fill(designDataOutput)

    if(!fill) return
    if(_.isObject(fill)){
        return fill
    }

    var layer = { name: layerDef.name,}
    if(_.isString(fill)) layer.color = fill
    if(layerDef.options) layer.options = layerDef.options
    return layer
}

function updateLayerParams(size, modelId, view, layer) {

    if(layer.type == 'render') {
        layer.crossOrigin = true;
        var query = {
            modelId: modelId,
            view: view,
            frameSize: { width: size.width, height: size.height },
        }

        if(layer.lettering)
            query.lettering = layer.lettering
        else if(layer.image) {
            var uri = window.location.origin + '/api/images/' + layer.image.uri.id + '/' + layer.image.uri.filename
            query.image = _.defaults({uri: uri}, layer.image)
        }
        else if(layer.patternFill)
            query.patternFill = layer.patternFill

        if(layer.placement) {
            query.placement = layer.placement
            query.placeholder = layer.placeholder
        }

        layer.src = 
            '/api/render3d?q=' + encodeURIComponent(JSON.stringify(query)) + '&2019-06-06'
    }
    else {
        var src = [
            '/canvas-preview-assets/2015-06-10/',
            size.id,
            '/',
            modelId,
            '-',
            view,
            '-',
            layer.name + '.png?2015-06-10'
        ];
        layer.src = src.join('');
    }
}

function getCanvasLayersExported(designDataOutput, size, activeView) {
    processData(designDataOutput);
    var modelId = designDataOutput.model;
    var canvasLayerConfig = layoutConfig[modelId];
    if(!canvasLayerConfig) {
        console.error("canvasLayerConfig not found for modelId", modelId);
        return [];
    }
    return _(canvasLayerConfig.defs)
        .filter(_.bind(isActiveView, this, activeView))
        .map(_.bind(createLayer, this, designDataOutput))
        .flatten()
        .filter(_.isObject)
        .forEach(_.bind(updateLayerParams, this, size, modelId, activeView))
        .value()
}

var renderCacheTimestamp = '__RENDER_CACHE_TIMESTAMP__'

function getCanvasLayers(designDataOutput, size, activeView) {
    var query = {
        view: activeView,
        size: size,
        code: garments[designDataOutput.jersey].key,
        design: designDataOutput,
    }

    return [
        {
            src: '/api/render3d/3d-full?q=' + encodeURIComponent(JSON.stringify(query)) + '&' + renderCacheTimestamp,
            crossOrigin: true,
        },
        {
            options: {blend: true},
            crossOrigin: true,
            src: [
                '/canvas-preview-assets',
                '2015-06-10',
                size.id,
                [
                    garments[designDataOutput.jersey].key,
                    activeView,
                    'tone',
                ].join('-') + '.png'
            ].join('/') + '?' + renderCacheTimestamp,
        }
    ]
}
