module.exports={
    "straight": {
        "name": "Straight",
        "code": "straight",
        "tailList": [
            "jet",
            "sweep",
            "standard",
            "stanford"
        ]
    },
    "bridge": {
        "name": "Bridge",
        "code": "bridge",
        "tailList": []
    },
    "verticalArch": {
        "name": "Vertical Arch",
        "code": "verticalArch",
        "tailList": []
    }
}