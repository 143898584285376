module.exports={
    "traditional": {
        "iconTemplate": "/assets/thumbnails/design/#JERSEY#-traditional.png",
        "name": "Traditional",
        "code": "traditional",
        "pipingLocationList": [
            "sleeve",
            "placket"
        ],
        "colorNameList": {
            "body": "Body",
            "designColor1": null,
            "designColor2": null,
            "designColor3": null
        },
        "designColorMap": {
            "designColor1": null,
            "designColor2": null,
            "designColor3": null
        }
    },
    "launch": {
        "iconTemplate": "/assets/thumbnails/design/#JERSEY#-launch.png",
        "name": "Launch",
        "code": "launch",
        "pipingLocationList": [
            "sleeve",
            "placket"
        ],
        "colorNameList": {
            "body": "Body",
            "designColor1": "Lower Stripes",
            "designColor2": "Upper Stripes",
            "designColor3": null
        },
        "designColorMap": {
            "designColor1": "launch.color2",
            "designColor2": "launch.color3",
            "designColor3": null
        }
    },
    "spider": {
        "iconTemplate": "/assets/thumbnails/design/#JERSEY#-spider.png",
        "name": "Spider",
        "code": "spider",
        "pipingLocationList": [
            "sleeve",
            "placket"
        ],
        "colorNameList": {
            "body": "Body",
            "designColor1": "Stripes",
            "designColor2": null,
            "designColor3": null
        },
        "designColorMap": {
            "designColor1": "spider.color3",
            "designColor2": null,
            "designColor3": null
        }
    },
    "spike": {
        "iconTemplate": "/assets/thumbnails/design/#JERSEY#-spike.png",
        "name": "Spike",
        "code": "spike",
        "pipingLocationList": [
            "sleeve",
            "placket"
        ],
        "colorNameList": {
            "body": "Body",
            "designColor1": "Stripes",
            "designColor2": null,
            "designColor3": null
        },
        "designColorMap": {
            "designColor1": "spike.color4",
            "designColor2": null,
            "designColor3": null
        }
    },
    "80SThrowback": {
        "iconTemplate": "/assets/thumbnails/design/#JERSEY#-80SThrowback.png",
        "name": "80s Throwback",
        "code": "80SThrowback",
        "pipingLocationList": [],
        "colorNameList": {
            "body": "Body",
            "designColor1": "Outer Stripes",
            "designColor2": "Inner Stripes",
            "designColor3": null
        },
        "designColorMap": {
            "designColor1": "80sThrowback.color3",
            "designColor2": "80sThrowback.color4",
            "designColor3": null
        }
    },
    "astroThrowback": {
        "iconTemplate": "/assets/thumbnails/design/#JERSEY#-astroThrowback.png",
        "name": "Astro Throwback",
        "code": "astroThrowback",
        "pipingLocationList": [],
        "colorNameList": {
            "body": "Lower Body",
            "designColor1": "Inner Stripes",
            "designColor2": "Middle Stripes",
            "designColor3": "Outer Stripes"
        },
        "designColorMap": {
            "designColor1": "AstroThrowback.color3",
            "designColor2": "AstroThrowback.color5",
            "designColor3": "AstroThrowback.color5"
        }
    }
}