module.exports={
    "proBlock": {
        "name": "Pro Block",
        "code": "proBlock",
        "tailList": [
            "sweep"
        ],
        "capHeight": 800,
        "outline1": 50,
        "lowercase": false
    },
    "pirate": {
        "name": "Pirate",
        "code": "pirate",
        "tailList": [
            "sweep"
        ],
        "capHeight": 1434,
        "outline1": 89,
        "lowercase": false
    },
    "varsity": {
        "name": "Varsity",
        "code": "varsity",
        "tailList": [
            "sweep"
        ],
        "capHeight": 750,
        "outline1": 46,
        "lowercase": false
    },
    "oldEnglish": {
        "name": "Old English",
        "code": "oldEnglish",
        "tailList": [
            "sweep"
        ],
        "capHeight": 750,
        "outline1": 46,
        "lowercase": true
    },
    "tiffany": {
        "name": "Tiffany",
        "code": "tiffany",
        "tailList": [
            "sweep"
        ],
        "capHeight": 750,
        "outline1": 46,
        "lowercase": false
    },
    "brush": {
        "name": "Brush",
        "code": "brush",
        "tailList": [
            "jet",
            "sweep",
            "standard",
            "stanford"
        ],
        "capHeight": 683,
        "outline1": 42,
        "lowercase": true
    },
    "script": {
        "name": "Script",
        "code": "script",
        "tailList": [
            "jet",
            "sweep",
            "standard",
            "stanford"
        ],
        "capHeight": 750,
        "outline1": 46,
        "lowercase": true
    }
}