module.exports={
    "YS": {
        "name": "YS",
        "printName": "YS",
        "code": "YS",
        "group": "Youth"
    },
    "YM": {
        "name": "YM",
        "printName": "YM",
        "code": "YM",
        "group": "Youth"
    },
    "YL": {
        "name": "YL",
        "printName": "YL",
        "code": "YL",
        "group": "Youth"
    },
    "YXL": {
        "name": "YXL",
        "printName": "YXL",
        "code": "YXL",
        "group": "Youth"
    },
    "AS": {
        "name": "AS",
        "printName": "AS",
        "code": "AS",
        "group": "Adult"
    },
    "AM": {
        "name": "AM",
        "printName": "AM",
        "code": "AM",
        "group": "Adult"
    },
    "AL": {
        "name": "AL",
        "printName": "AL",
        "code": "AL",
        "group": "Adult"
    },
    "AXL": {
        "name": "AXL",
        "printName": "AXL",
        "code": "AXL",
        "group": "Adult"
    },
    "AXXL": {
        "name": "AXXL",
        "printName": "AXXL",
        "code": "AXXL",
        "group": "Adult"
    }
}