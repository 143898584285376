/*jslint node: true */
"use strict";

module.exports = {
    shadow: {
        min: -100,
        max: 190,
        strength: 1,
    },
    highlight: {
        min: 130,
        max: 360,
        strength: 0.5,
    },
}
