/*jslint node: true */
"use strict";

var _ = require('lodash')
var mapColor = require('../../viz/lib/utils').mapColor
var getAltColor = require('../../viz/lib/utils').getAltColor
var getFill = require('../../viz/lib/utils').getFill
var data = require('../../data')

var garments = data.garments
var designs = data.designs
var camoColors = data.whitelists.colors.camo

var containerList = [
    'back',
    'front',
    'frontLeft',
    'frontRight',
    'sleeveLeft',
    'sleeveRight',

    'collar',
    'collarIn',
    'collarOut',

    'collarBackIn',
    'collarFrontIn',
    'collarFrontLeftIn',
    'collarFrontRightIn',

    'collarBackOut',
    'collarFrontOut',
    'collarFrontLeftOut',
    'collarFrontRightOut',

    'button1',
    'button2',
    'button3',
    'button4'
]

var bodyContainers = [
    'back',
    'front',
    'frontLeft',
    'frontRight',
]

var sleeveContainers = [
    'sleeveLeft',
    'sleeveRight',
]

var placketContainers = [
    'collar',
    'collarIn',
    'collarOut',

    'collarBackIn',
    'collarFrontIn',
    'collarFrontLeftIn',
    'collarFrontRightIn',

    'collarBackOut',
    'collarFrontOut',
    'collarFrontLeftOut',
    'collarFrontRightOut',
]

var buttonContainers = [
    'button1',
    'button2',
    'button3',
    'button4',
]

var placketOutLayers = [
    'collar',
    'collarOut',

    'collarBackOut',
    'collarFrontOut',
    'collarFrontLeftOut',
    'collarFrontRightOut',
]

function isContainer(key) {
    return _.some(key.split('|'),
        function (frag) { return _.contains(containerList, frag) })
}

function concatStringFn(str){
    return function(input){
        return input + str
    }
}

function getFillName(key) {
    var fillNames = {
        //button: '.fill'
    }
    return key + (fillNames[key] || (isContainer(key) ? '.mask' : ''))
}

function getSeamName(key) {
    return key + '.beauty'
}

function getFillsSeams(parts, defConfig) {
    return _.transform(parts, function (result, layers, key) {
        var fillLayers = _.map(layers, getFillName)
        result[key] = _.assign({
            views: 'all',
            layers: fillLayers,
            sides: 'both',
            fill: function (data) {
                return getFill(data.colors[key], fillLayers)
            },
        }, defConfig)

        var seamLayers = _(layers).filter(isContainer).map(getSeamName).value()
        if (seamLayers.length > 0) {
            result[key + 'Seams'] = _.assign({
                views: 'all',
                layers: seamLayers,
                fill: function (data) {
                    return mapColor(data.colors[key], 'hexSeams')
                },
                attributes: {strokeWidth: 1},
            }, defConfig)
        }
    })
}

var defs = {
    // debug: {
    //     debug: {
    //         views: 'all',
    //         layers: ['*'],
    //         skipRecolor: true,
    //     }
    // },
    body: getFillsSeams({
        body: [_.union(bodyContainers, buttonContainers).join('|')],
    }),
    sleeves: getFillsSeams({
        sleeves: [sleeveContainers.join('|')],
    }),
    placketOrCollar: getFillsSeams({
        placketOrCollar: [_.union(placketContainers, buttonContainers).join('|')],
    }, {
        export: function(garment) {
            return garment.hasPlacket || garment.hasCollar
        }
    }),
    designs: _.transform(designs, function(result, designData){
        _.each(designData.designColorMap, function(layerPath, colorProp){
            if(!layerPath) return
            result[designData.code+'-'+colorProp] = {
                views: 'all',
                layers: ['*.'+layerPath],
                fill: function (data) {
                    return data.design === designData.code && mapColor(data.colors[colorProp])
                },
                export: function(garment) {
                    return _.contains(garment.designList, designData.code)
                },
            }
        })
    }, {
        spiderWhite: {
            views: 'all',
            layers: ['*.spider.color2'],
            attributes: {fill: '#ffffff'},
            skipRecolor: true,
            fill: function (data) {
                return data.design === 'spider'
            },
            export: function(garment) {
                return _.contains(garment.designList, 'spider')
            },
        }
    }),
    piping: _.chain(
        _.map(['sleevePiping', 'placketPiping'], function(location){
            var locationShort = location.replace('Piping', '')
            return _.map([1,2], function(index){
                return {
                    name: location + index,
                    views: 'all',
                    layers:  ['*.'+location+'.color'+index],
                    export: function(garment){
                        return _.contains(garment.pipingLocationList, locationShort)
                    },
                    fill: function (data) {
                        return data.piping[location] === 'on' && mapColor(data.piping['color'+index])
                    },
                }
            })
        }))
        .flatten()
        .indexBy('name')
        .value(),
    dynamicLayers: _.mapValues(data.decos, function(){
        return {
            views: 'all',
            placeholder: true,
            fill: true,
            isDynamic: true,
        }
    }),
    aboveDesign: {
        marucciLogo: {
            views: ['right', 'left', 'back'],
            layers: ['back.marucciLogo'],
            attributes: { fill: '#f0f0f0' }, // todo: color code should be taken from actual colorList
            fill: true,
        },
        buttons: {
            views: ['front', 'left', 'right'],
            layers: [buttonContainers.join('|') + '.mask'],
            export: function(garment) {
                return garment.hasButtons
            },
            attributes: { opacity: '0.8', fill: '#eeeeff', clipPath: 'none' }, // todo: color code should be taken from actual colorList
            fill: true,
        },
        buttonSeams: {
            views: ['front', 'left', 'right'],
            layers: [buttonContainers.join('|') + '.beauty'],
            fill: function (data) {
                var jersey = garments[data.jersey]
                var buttonSeams = data.colors[jersey.hasPlacket ? 'placketOrCollar' : 'body']
                return mapColor(buttonSeams)
            },
            export: function(garment) {
                return garment.hasButtons
            },
        },
    },
    inside: {
        insideOverlay: {
            views: 'all',
            layers: [
                'back.mask',
                'front|frontLeft|frontRight.mask',
                'sleeveLeft|sleeveRight.mask',
            ],
            sides: 'inside',
            attributes: {opacity: '0.4', fill: '#e8e8e8', clipPath: 'none'},
            fill: true,
        },
        insideDetails: {
           views: 'all',
           layers: ['back.label'],
           sides: 'inside',
           skipRecolor: true,
           fill: true,
        },
    },
    tone: {
        tone: {
            texture: true,
            composite: {
                inside: 'inside',
                outside: 'outside',
            },
            sides: 'both',
            views: 'all',
            options: {blend: true},
            fill: true,
        },
    },
}

function flattenRanges (ranges) {
    return _.reduce(ranges, function(result, range, key) {
        return _.transform(result, function (newResult, inputItem) {
            _.each(range, function (val) {
                var newItem = _.chain(inputItem)
                    .clone()
                    .set(key, val)
                    .value()
                newResult.push(newItem)
            });
        })
    }, [{}])
}


function shouldExport(garment, layer) {
    if (_.isFunction(layer.export)) {
        return layer.export(garment);
    }
    return true;
}

var modelList = flattenRanges({
    silhouetteId: _.range(1, 4 + 1),
    sleeveId: _.range(1, 2 + 1),
})

module.exports = _.transform(modelList, function(result, modelDef) {
    var silhouetteId = modelDef.silhouetteId
    var sleeveId = modelDef.sleeveId
    var id = silhouetteId + '.' + sleeveId
    var garment = _.find(garments, {key: id}) || {};
    result[id] = {
        model: 'MarucciBJ_' + id,
        texture: 'MAR_BBJ_' + id,
        defs: _({})
            .assign(
                defs.body,
                defs.sleeves,
                defs.placketOrCollar,
                defs.designs,
                defs.piping,
                defs.dynamicLayers,
                {
                    dynamicLayers:{}
                },
                defs.aboveDesign,
                defs.inside,
                defs.tone
            )
            .forEach(function(layer, name) {
                layer.name = name;
            })
            .omit(function(layer, name) {
                return !shouldExport(garment, layer)
            })
            .value()
    }
}, {})
