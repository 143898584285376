module.exports={
    "colors": {
        "none": [
            "none"
        ],
        "solid": [
            "white",
            "lightGrey",
            "grey",
            "black",
            "forest",
            "kelly",
            "limeGreen",
            "hopkinsBlue",
            "divaBlue",
            "royal",
            "navy",
            "purple",
            "texasOrange",
            "vegas",
            "athleticGold",
            "higlighterYellow",
            "maize",
            "tennesseeOrange",
            "orange",
            "shockingPink",
            "pink",
            "red",
            "cardinal",
            "maroon",
            "brown"
        ],
        "camo": [
            "digitalSnow",
            "digitalSand",
            "digitalTan",
            "digitalDark"
        ],
        "tackleTwill": [
            "white",
            "grey"
        ],
        "solidAndCamo": [
            "white",
            "lightGrey",
            "grey",
            "black",
            "forest",
            "kelly",
            "limeGreen",
            "hopkinsBlue",
            "divaBlue",
            "royal",
            "navy",
            "purple",
            "texasOrange",
            "vegas",
            "athleticGold",
            "higlighterYellow",
            "maize",
            "tennesseeOrange",
            "orange",
            "shockingPink",
            "pink",
            "red",
            "cardinal",
            "maroon",
            "brown",
            "digitalSnow",
            "digitalSand",
            "digitalTan",
            "digitalDark"
        ],
        "solidOptional": [
            "none",
            "white",
            "lightGrey",
            "grey",
            "black",
            "forest",
            "kelly",
            "limeGreen",
            "hopkinsBlue",
            "divaBlue",
            "royal",
            "navy",
            "purple",
            "texasOrange",
            "vegas",
            "athleticGold",
            "higlighterYellow",
            "maize",
            "tennesseeOrange",
            "orange",
            "shockingPink",
            "pink",
            "red",
            "cardinal",
            "maroon",
            "brown"
        ]
    },
    "decoTypes": {
        "all": [
            "sublimation",
            "tackleTwill"
        ]
    },
    "garments": {
        "sublimation": [
            "2ButtonHenley-raglan-sublimation",
            "2ButtonHenley-setIn-sublimation",
            "crewNeck-raglan-sublimation",
            "crewNeck-setIn-sublimation",
            "fullButton-raglan-sublimation",
            "fullButton-setIn-sublimation",
            "vNeck-raglan-sublimation",
            "vNeck-setIn-sublimation"
        ],
        "tackleTwill": [
            "fullButton-setIn-tackleTwill"
        ],
        "all": [
            "2ButtonHenley-raglan-sublimation",
            "2ButtonHenley-setIn-sublimation",
            "crewNeck-raglan-sublimation",
            "crewNeck-setIn-sublimation",
            "fullButton-raglan-sublimation",
            "fullButton-setIn-sublimation",
            "vNeck-raglan-sublimation",
            "vNeck-setIn-sublimation",
            "fullButton-setIn-tackleTwill"
        ]
    },
    "designs": {
        "all": [
            "traditional",
            "launch",
            "spider",
            "spike",
            "80SThrowback",
            "astroThrowback"
        ]
    },
    "decos": {
        "all": [
            "frontLogo",
            "teamname",
            "frontNumber",
            "backNumber",
            "playerName",
            "sleeveLogo"
        ]
    },
    "decoLocations": {
        "all": [
            "fullFront",
            "leftChest",
            "lowerLeftChest",
            "fullBack",
            "upperBack",
            "leftSleeve",
            "none"
        ]
    },
    "fonts": {
        "all": [
            "proBlock",
            "pirate",
            "varsity",
            "oldEnglish",
            "tiffany",
            "brush",
            "script"
        ]
    },
    "layouts": {
        "all": [
            "straight",
            "bridge",
            "verticalArch"
        ]
    },
    "tailings": {
        "all": [
            "jet",
            "sweep",
            "standard",
            "stanford"
        ]
    },
    "rosterSizes": {
        "all": [
            "YS",
            "YM",
            "YL",
            "YXL",
            "AS",
            "AM",
            "AL",
            "AXL",
            "AXXL"
        ]
    }
}