module.exports={
    "frontLogo": {
        "name": "Front Logo",
        "code": "frontLogo",
        "decoLocationList": [
            "none",
            "fullFront",
            "leftChest"
        ],
        "fontList": [],
        "layoutList": []
    },
    "teamname": {
        "name": "Teamname",
        "code": "teamname",
        "decoLocationList": [
            "none",
            "fullFront",
            "leftChest"
        ],
        "fontList": [
            "proBlock",
            "pirate",
            "varsity",
            "oldEnglish",
            "tiffany",
            "brush",
            "script"
        ],
        "layoutList": [
            "straight",
            "bridge",
            "verticalArch"
        ]
    },
    "frontNumber": {
        "name": "Front Number",
        "code": "frontNumber",
        "decoLocationList": [
            "none",
            "lowerLeftChest"
        ],
        "fontList": [
            "proBlock",
            "pirate",
            "varsity",
            "tiffany"
        ],
        "layoutList": [
            "straight"
        ]
    },
    "backNumber": {
        "name": "Back Number",
        "code": "backNumber",
        "decoLocationList": [
            "none",
            "fullBack"
        ],
        "fontList": [
            "proBlock",
            "pirate",
            "varsity",
            "tiffany"
        ],
        "layoutList": [
            "straight"
        ]
    },
    "playerName": {
        "name": "Player Name",
        "code": "playerName",
        "decoLocationList": [
            "none",
            "upperBack"
        ],
        "fontList": [
            "proBlock",
            "varsity"
        ],
        "layoutList": [
            "straight",
            "verticalArch"
        ]
    },
    "sleeveLogo": {
        "name": "Sleeve Logo",
        "code": "sleeveLogo",
        "decoLocationList": [
            "none",
            "leftSleeve"
        ],
        "fontList": [],
        "layoutList": []
    }
}