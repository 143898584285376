module.exports={
    "fullFront": {
        "name": "Full Front",
        "code": "fullFront"
    },
    "leftChest": {
        "name": "Left Chest",
        "code": "leftChest"
    },
    "lowerLeftChest": {
        "name": "Lower Left Chest",
        "code": "lowerLeftChest"
    },
    "fullBack": {
        "name": "Full Back",
        "code": "fullBack"
    },
    "upperBack": {
        "name": "Upper Back",
        "code": "upperBack"
    },
    "leftSleeve": {
        "name": "Left Sleeve",
        "code": "leftSleeve"
    },
    "none": {
        "name": "None",
        "code": "none"
    }
}