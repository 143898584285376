module.exports={
    "none": {
        "name": "None",
        "code": "none",
        "hex": null,
        "overlayColor": "black",
        "isCamo": false,
        "isSolid": false,
        "tackleTwill": false
    },
    "white": {
        "hexStitching": "#e5e5e5",
        "name": "White",
        "code": "white",
        "hex": "#f0f0f0",
        "overlayColor": "black",
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": true
    },
    "lightGrey": {
        "hexStitching": "#b7babc",
        "name": "Light Grey",
        "code": "lightGrey",
        "hex": "#bec0c2",
        "overlayColor": "black",
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "grey": {
        "hexStitching": "#4a594e",
        "name": "Grey",
        "code": "grey",
        "hex": "#455349",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": true
    },
    "black": {
        "hexStitching": "#2d2829",
        "name": "Black",
        "code": "black",
        "hex": "#231f20",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "forest": {
        "hexStitching": "#005c1e",
        "name": "Forest",
        "code": "forest",
        "hex": "#004a18",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "kelly": {
        "hexStitching": "#00ae4b",
        "name": "Kelly",
        "code": "kelly",
        "hex": "#00a547",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "limeGreen": {
        "hexStitching": "#7dc242",
        "name": "Lime Green",
        "code": "limeGreen",
        "hex": "#7dc242",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "hopkinsBlue": {
        "hexStitching": "#6caacb",
        "name": "Hopkins Blue",
        "code": "hopkinsBlue",
        "hex": "#71adcd",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "divaBlue": {
        "hexStitching": "#00b3c8",
        "name": "Diva Blue",
        "code": "divaBlue",
        "hex": "#00aec2",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "royal": {
        "hexStitching": "#0069a1",
        "name": "Royal",
        "code": "royal",
        "hex": "#006296",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "navy": {
        "hexStitching": "#00074c",
        "name": "Navy",
        "code": "navy",
        "hex": "#000538",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "purple": {
        "hexStitching": "#373f9d",
        "name": "Purple",
        "code": "purple",
        "hex": "#363e99",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "texasOrange": {
        "hexStitching": "#a04707",
        "name": "Texas Orange",
        "code": "texasOrange",
        "hex": "#974307",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "vegas": {
        "hexStitching": "#d5ad54",
        "name": "Vegas",
        "code": "vegas",
        "hex": "#d6af58",
        "overlayColor": "black",
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "athleticGold": {
        "hexStitching": "#febf22",
        "name": "Athletic Gold",
        "code": "athleticGold",
        "hex": "#fec026",
        "overlayColor": "black",
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "higlighterYellow": {
        "hexStitching": "#efe90a",
        "name": "Higlighter Yellow",
        "code": "higlighterYellow",
        "hex": "#eee80a",
        "overlayColor": "black",
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "maize": {
        "hexStitching": "#ffd41d",
        "name": "Maize",
        "code": "maize",
        "hex": "#ffd520",
        "overlayColor": "black",
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "tennesseeOrange": {
        "hexStitching": "#f89525",
        "name": "Tennessee Orange",
        "code": "tennesseeOrange",
        "hex": "#f89728",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "orange": {
        "hexStitching": "#f15e20",
        "name": "Orange",
        "code": "orange",
        "hex": "#f15f22",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "shockingPink": {
        "hexStitching": "#de3b95",
        "name": "Shocking Pink",
        "code": "shockingPink",
        "hex": "#de3d96",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "pink": {
        "hexStitching": "#ec7897",
        "name": "Pink",
        "code": "pink",
        "hex": "#ed829f",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "red": {
        "hexStitching": "#b91935",
        "name": "Red",
        "code": "red",
        "hex": "#b51834",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "cardinal": {
        "hexStitching": "#7a0005",
        "name": "Cardinal",
        "code": "cardinal",
        "hex": "#6b0004",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "maroon": {
        "hexStitching": "#580000",
        "name": "Maroon",
        "code": "maroon",
        "hex": "#450000",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "brown": {
        "hexStitching": "#561200",
        "name": "Brown",
        "code": "brown",
        "hex": "#430e00",
        "overlayColor": null,
        "isCamo": false,
        "isSolid": true,
        "tackleTwill": false
    },
    "digitalSnow": {
        "name": "Digital Snow",
        "code": "digitalSnow",
        "hex": null,
        "overlayColor": "black",
        "isCamo": true,
        "isSolid": false,
        "tackleTwill": false
    },
    "digitalSand": {
        "name": "Digital Sand",
        "code": "digitalSand",
        "hex": null,
        "overlayColor": "black",
        "isCamo": true,
        "isSolid": false,
        "tackleTwill": false
    },
    "digitalTan": {
        "name": "Digital Tan",
        "code": "digitalTan",
        "hex": null,
        "overlayColor": "black",
        "isCamo": true,
        "isSolid": false,
        "tackleTwill": false
    },
    "digitalDark": {
        "name": "Digital Dark",
        "code": "digitalDark",
        "hex": null,
        "overlayColor": "black",
        "isCamo": true,
        "isSolid": false,
        "tackleTwill": false
    }
}