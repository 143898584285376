module.exports={
    "2ButtonHenley-raglan-sublimation": {
        "assetId": "1.1_2ButtonHenley-raglan",
        "icon": "/assets/thumbnails/jersey/2ButtonHenley-raglan.png",
        "key": "1.1",
        "code": "2ButtonHenley-raglan-sublimation",
        "assetCode": "2ButtonHenley-raglan",
        "name": "2 Button - Raglan Sleeve",
        "decorationType": "sublimation",
        "hasButtons": true,
        "hasPlacket": true,
        "hasCollar": false,
        "designList": [
            "traditional",
            "launch",
            "spider",
            "spike"
        ],
        "pipingLocationList": [
            "sleeve",
            "placket"
        ]
    },
    "2ButtonHenley-setIn-sublimation": {
        "assetId": "1.2_2ButtonHenley-setIn",
        "icon": "/assets/thumbnails/jersey/2ButtonHenley-setIn.png",
        "key": "1.2",
        "code": "2ButtonHenley-setIn-sublimation",
        "assetCode": "2ButtonHenley-setIn",
        "name": "2 Button - Set In Sleeve",
        "decorationType": "sublimation",
        "hasButtons": true,
        "hasPlacket": true,
        "hasCollar": false,
        "designList": [
            "traditional",
            "launch",
            "spider"
        ],
        "pipingLocationList": [
            "sleeve",
            "placket"
        ]
    },
    "crewNeck-raglan-sublimation": {
        "assetId": "2.1_crewNeck-raglan",
        "icon": "/assets/thumbnails/jersey/crewNeck-raglan.png",
        "key": "2.1",
        "code": "crewNeck-raglan-sublimation",
        "assetCode": "crewNeck-raglan",
        "name": "Crew Neck - Raglan Sleeve",
        "decorationType": "sublimation",
        "hasButtons": false,
        "hasPlacket": false,
        "hasCollar": true,
        "designList": [
            "traditional",
            "launch",
            "spider",
            "spike",
            "80SThrowback",
            "astroThrowback"
        ],
        "pipingLocationList": [
            "sleeve"
        ]
    },
    "crewNeck-setIn-sublimation": {
        "assetId": "2.2_crewNeck-setIn",
        "icon": "/assets/thumbnails/jersey/crewNeck-setIn.png",
        "key": "2.2",
        "code": "crewNeck-setIn-sublimation",
        "assetCode": "crewNeck-setIn",
        "name": "Crew Neck - Set In Sleeve",
        "decorationType": "sublimation",
        "hasButtons": false,
        "hasPlacket": false,
        "hasCollar": true,
        "designList": [
            "traditional",
            "launch",
            "spider",
            "80SThrowback",
            "astroThrowback"
        ],
        "pipingLocationList": [
            "sleeve"
        ]
    },
    "fullButton-raglan-sublimation": {
        "assetId": "3.1_fullButton-raglan",
        "icon": "/assets/thumbnails/jersey/fullButton-raglan.png",
        "key": "3.1",
        "code": "fullButton-raglan-sublimation",
        "assetCode": "fullButton-raglan",
        "name": "Full Button - Raglan Sleeve",
        "decorationType": "sublimation",
        "hasButtons": true,
        "hasPlacket": true,
        "hasCollar": false,
        "designList": [
            "traditional",
            "launch",
            "spider",
            "spike"
        ],
        "pipingLocationList": [
            "sleeve",
            "placket"
        ]
    },
    "fullButton-setIn-sublimation": {
        "assetId": "3.2_fullButton-setIn",
        "icon": "/assets/thumbnails/jersey/fullButton-setIn.png",
        "key": "3.2",
        "code": "fullButton-setIn-sublimation",
        "assetCode": "fullButton-setIn",
        "name": "Full Button - Set In Sleeve",
        "decorationType": "sublimation",
        "hasButtons": true,
        "hasPlacket": true,
        "hasCollar": false,
        "designList": [
            "traditional",
            "launch",
            "spider"
        ],
        "pipingLocationList": [
            "sleeve",
            "placket"
        ]
    },
    "vNeck-raglan-sublimation": {
        "assetId": "4.1_vNeck-raglan",
        "icon": "/assets/thumbnails/jersey/vNeck-raglan.png",
        "key": "4.1",
        "code": "vNeck-raglan-sublimation",
        "assetCode": "vNeck-raglan",
        "name": "V Neck - Raglan Sleeve",
        "decorationType": "sublimation",
        "hasButtons": false,
        "hasPlacket": false,
        "hasCollar": true,
        "designList": [
            "traditional",
            "launch",
            "spider",
            "spike",
            "80SThrowback",
            "astroThrowback"
        ],
        "pipingLocationList": [
            "sleeve"
        ]
    },
    "vNeck-setIn-sublimation": {
        "assetId": "4.2_vNeck-setIn",
        "icon": "/assets/thumbnails/jersey/vNeck-setIn.png",
        "key": "4.2",
        "code": "vNeck-setIn-sublimation",
        "assetCode": "vNeck-setIn",
        "name": "V Neck - Set In Sleeve",
        "decorationType": "sublimation",
        "hasButtons": false,
        "hasPlacket": false,
        "hasCollar": true,
        "designList": [
            "traditional",
            "launch",
            "spider",
            "80SThrowback",
            "astroThrowback"
        ],
        "pipingLocationList": [
            "sleeve"
        ]
    },
    "fullButton-setIn-tackleTwill": {
        "assetId": "3.2_fullButton-setIn",
        "icon": "/assets/thumbnails/jersey/fullButton-setIn.png",
        "key": "3.2",
        "code": "fullButton-setIn-tackleTwill",
        "assetCode": "fullButton-setIn",
        "name": "Full Button - Set In",
        "decorationType": "tackleTwill",
        "hasButtons": true,
        "hasPlacket": true,
        "hasCollar": false,
        "designList": [
            "traditional"
        ],
        "pipingLocationList": [
            "sleeve",
            "placket"
        ]
    }
}